module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ee089598edf45305095ee0cf68af7228"},
    },{
      plugin: require('../node_modules/@nathanpate/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"mode":"async","preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Russo+One","file":"https://fonts.googleapis.com/css2?family=Russo+One&display=swap"},{"name":"impact","file":"https://fonts.cdnfonts.com/css/impact"},{"name":"DM+Sans","file":"https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
